<template>
  <img
    alt="Decorative bar of triskele art"
    class="break"
    src="../assets/triskele-bar.png"
  />
</template>

<script>
export default {};
</script>
