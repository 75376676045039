<template>
  <main>
    <section class="upper-left">
      <h2>Maggie Mc Ateer</h2>
      <p>CThA, LCICI, VTCT</p>
      <figure>
        <img
          src="../assets/maggie-mcateer.jpg"
          alt="Maggie McAteer, complementary therapist"
        />
        <figcaption>
          Maggie Mc Ateer is a Complementary Therapist based in Winterton, North
          Lincolnshire. Maggie offers a range of treatments to her clients and
          also teaches courses in Indian Head Massage, Natural Facials and Ear
          Candling.
        </figcaption>
      </figure>
    </section>

    <section class="upper-right">
      <h2>Qualifications</h2>
      <ul>
        <li>Member of the Complementary Therapists Association (CThA)</li>
        <li>
          Level 3 Diploma in Reflexology, accredited by the Vocational Training
          Charitable Trust (VCTC)
        </li>
        <li>
          Level 3 Certificate in Swedish Massage, accredited by the Vocational
          Training Charitable Trust (VCTC)
        </li>
        <li>
          Diploma in Indian Champissage (Head Massage) from The London Centre of
          Indian Champissage International(LCICI)
        </li>
        <li>
          Certificate in Hopi Ear Candling - Approved by Embody (CThA) and
          recognized by the Complementary Medical Association (CMA)
        </li>
        <li>
          Certificate in Natural Facials - Approved by Embody (CThA) and
          recognized by the Complementary Medical Association (CMA)
        </li>
        <li>
          Diploma in Natural Face Lift Massage(Facial Rejuvenation)- LCICI
        </li>
        <li>Certificate in Kansa Vatki Foot Massage -LCICI</li>
      </ul>
    </section>

    <section class="lower-full">
      <h2>Introduction</h2>
      <p>
        "I first became interested in holistic therapies after having an Indian
        Head Massage. The complete relaxation and deep sense of well being that
        I experienced inspired me to learn how to give this wonderful treatment
        to others.
      </p>
      <p>
        Since then my passion for holistic treatments has expanded greatly, and
        has lead me to develop my own practice. I now offer a variety of
        therapies, including Reflexology, Swedish Massage and Hot Stone Therapy.
      </p>
      <p>
        I have a dedicated treatment room, and also offer a mobile service for
        those who prefer to receive treatments at home. I treat each client as
        an individual and tailor the treatment to their specific requirements.
      </p>
      <p>
        I love what I do for a living as it gives me the opportunity to work
        with people and to be able to contribute to their quality of life. I
        named my business after the ancient Celtic symbol of Triskele because of
        the many different meanings that have been attributed to it throughout
        the ages. For me it represents the harmony of Mind, Body and Soul, which
        I believe is the key to well-being.
      </p>
      <p>
        In addition to being a practicing Therapist, I also teach a Diploma
        course in Indian Head Massage and courses in Natural Facials and Ear
        Candling."
      </p>
    </section>
  </main>
</template>

<script>
export default {
  name: "HomePage",
};
</script>
