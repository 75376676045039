<template>
  <img
    alt="Decorative bar of triskele art"
    src="../assets/triskele-bar-small.png"
  />
</template>

<script>
export default {};
</script>
