<template>
  <main>
    <div class="upper-left">
      <figure>
        <img
          alt="Peaceful waterlily painting"
          class="center"
          src="../assets/2009.jpg"
        />
      </figure>
    </div>
    <section class="upper-right">
      <h2>Courses</h2>
      <p>
        I am a member of
        <a href="http://www.hands-on-training.net/">Hands on Training</a>, a
        recognised organisation that offers courses registered with the CMA
        (Complementary Medical Association).
      </p>
      <ul>
        <li><a href="#course-ear-candling">Ear Candling</a></li>
        <li><a href="#course-indian-massage">Indian Head Massage</a></li>
        <li><a href="#course-natural-facial">Natural Facial</a></li>
      </ul>
    </section>

    <section class="lower-full" id="courses-info">
      <BreakBar />
      <article id="course-ear-candling">
        <h2>Ear Candling</h2>
        <p>
          This fascinating therapy has gained popularity in recent years as we
          seek natural forms of healthcare. Ear Candling is a gentle,
          non-invasive therapy that is easy to perform and beneficial to
          receive. It is soothing and helpful for conditions experienced by many
          of us - such as headaches, compacted ear wax, sinus problems,
          tinnitus, pressure problems and much more. It is also a very relaxing
          therapy in its own right and helpful with the treatment of stress.
        </p>
        <h3>Course format:</h3>
        <ul>
          <li>History of Ear Candling.</li>
          <li>How the ear candles work.</li>
          <li>Compare types of candles on the market.</li>
          <li>Benefits and contraindication.</li>
          <li>Anatomy of the ear and sinuses.</li>
          <li>Safety.</li>
          <li>
            Observe, carry out and receive a full treatment, including ear
            candling and a specialised massage sequence of the face, neck, scalp
            and ears (a full treatments lasts 45 minutes).
          </li>
          <li>Client consultation.</li>
          <li>Frequency of treatments.</li>
          <li>Final assessment.</li>
        </ul>
        <h3>Recognition:</h3>
        <p>
          This course provides a professional qualification and is recognised
          for 5 CPD points.
        </p>
        <BreakBarSmall />
      </article>
      <article id="course-indian-massage">
        <h2>Indian Head Massage</h2>
        <p>
          Indian Head Massage is highly effective, convenient and one of the
          most popular therapies available today. A treatment includes massage
          of the upper back, shoulders, arms, neck, scalp, face and energy
          balancing. Clients are not required to undress and no special
          equipment is needed, making treatments accessible to many. A full
          treatment takes 30 minutes but in some circumstances (e.g. for
          corporate clients) 15 or 20 minute sessions are popular.
        </p>
        <p>
          <em
            >We offer a 3 day diploma course to ensure a high level of
            competency.</em
          >
        </p>
        <h3>Course format:</h3>
        <ul>
          <li>
            <h4>Practical:</h4>
            <p>
              Specialised techniques, which can be done through clothes, include
              effleurage, friction, kneading, percussion, compression and
              pressure points. New massage skills are taught each day, plus time
              to refine and consolidate techniques.
            </p>
          </li>
          <li>
            <h4>Theory:</h4>
            <p>
              This includes history, contra-indications, benefits, headaches,
              stress, client consultation, the use of oils, chakra energy and
              study of the relevant anatomy.
            </p>
          </li>
          <li>
            <h4>Assessment:</h4>
            <p>
              Following the 3 day course students are required to do 5 case
              studies (5 treatments each), a practical assessment and a multiple
              choice theory paper.
            </p>
          </li>
        </ul>
        <BreakBarSmall />
      </article>
      <article id="course-natural-facial">
        <h2>Natural Facial</h2>
        <p>
          On this popular course you will learn a truly natural facial
          treatment, including cleanse, tone, warm compress, face pack and
          specialised massage sequence using plant based oils, creams, gels and
          aromatic waters. The products used contain no potentially harmful
          substances such as artificial preservatives or chemicals such as
          parabens or SLS and are readily available from several suppliers, or
          even your own kitchen (eg honey, avocado). The course fee includes a
          starter pack of products (some you will make on the course) so you can
          immediately start using your new skills.
        </p>
        <h3>Course format:</h3>
        <ul>
          <li><p>Why use natural products?</p></li>
          <li><p>How Natural Facial works.</p></li>
          <li><p>Benefits and contraindications.</p></li>
          <li><p>Skin analysis.</p></li>
          <li>
            <p>
              Overview of products used - cleanser, aromatic waters, face packs,
              eye treatments, carrier oils, includes making a cleanser, eye gel
              and face pack.
            </p>
          </li>
          <li>
            <h4>Practical:</h4>
            <p>
              Observe, carry out and receive a full treatment including cleanse,
              tone, compress, face pack, and specialised massage of the face and
              neck.
            </p>
          </li>
        </ul>
        <h3>Recognition:</h3>
        <p>
          This course provides a professional qualification and is recognised
          for 5 CPD points.
        </p>
        <h3>Who is eligible to attend?</h3>
        <p>
          The course is suitable for qualified therapists, eg those qualified in
          one or more of the following skills: Aromatherapy, Massage,
          Reflexology, Indian Head Massage, beauty therapy etc.
        </p>
        <BreakBarSmall />
      </article>
      <article class="bookings">
        <h2>Bookings</h2>
        <p>
          Please <a href="contact">contact</a> me for dates of upcoming courses.
        </p>
        <BreakBar />
      </article>
    </section>
  </main>
</template>

<script>
import BreakBar from "../components/BreakBar.vue";
import BreakBarSmall from "../components/BreakBarSmall.vue";

export default {
  name: "CoursesPage",
  components: {
    BreakBar,
    BreakBarSmall,
  },
};
</script>
